<template>
    <div class="row">
        <div class="col-12">
            <card>
                <page-header
                title="MAGAZZINO"
                :query="query"
                @backClick="$router.back()"
                />
                <list-table
                    v-model="query"
                    :pagination="pagination"
                    :columns="tableColumns"
                    :items="visibleProducts"
                    :meta="meta"
                    :creable="false"
                    :search-fields="searchFields"
                    hide-default-search
                    :deletable="$isAdmin()"
                    :editable="false"
                    @onShow="openShowModal($event)"
                    @onDelete="handleDelete($event)"
                >
                    <template #customActions="props">
                        <el-tooltip v-if="$isAdmin()" content="Modifica Assegnazioni"
                            :effect="props.darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
                            <base-button class="edit btn-link" type="warning" size="sm" icon
                                @click.native="openAssignationsModal(props.row)">
                                <i class="tim-icons icon-pencil" />
                            </base-button>
                        </el-tooltip>
                        <el-tooltip v-if="$isAdmin()" :content="(props.row.visible) ? 'Nascondi' : 'Mostra'"
                                    :effect="props.darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
                          <base-button class="edit btn-link" type="warning" size="sm" icon
                                       @click.native="toggleProductVisibility(props.row)">
                            <i :class="'tim-icons icon-button-power ' + ((props.row.visible) ? 'ico-show' : 'ico-hide')" />
                          </base-button>
                        </el-tooltip>
                    </template>
                </list-table>
            </card>

            <modal :show.sync="viewModal" @close="closeShowModal" class="small-modal">
                <div class="row">
                    <div v-if="selectedProduct" class="col-12">
                        <div>
                            <b>{{ selectedProduct.description }}</b>
                        </div>
                        <div class="mt-5">
                            <b>Prodotti Ordinabili: </b>
                            {{ selectedProduct.storage.qty_orderable }}
                        </div>
                        <div>
                            <b>Prodotti In Prenotazione: </b>
                            {{ selectedProduct.reserved_storage.reserved_qty }}
                        </div>
                        <div>
                            <b>Prodotti Effettivi in Magazzino: </b>
                            {{ selectedProduct.stock_storage.qty_orderable }}
                        </div>
                        <div>
                            <b>Prodotti impegnati: </b>
                            {{ selectedProduct.storage.qty_imp_cli }}
                        </div>
                        <div>
                            <b>Prodotti in ordine al fornitore: </b>
                            {{ selectedProduct.storage.qty_ord_for }}
                        </div>
                        <div v-if="selectedProduct.storage.next_arrival_from_supplier">
                            <b>Prima data di arrivo merce: </b>
                            {{ selectedProduct.storage.next_arrival_from_supplier }}
                        </div>
                        <div v-if="selectedProduct.note" class="mt-4">
                          <b>Nota: </b>
                          {{ selectedProduct.note }}
                        </div>
                        <div v-if="$isAdmin()" class="mt-4">
                            <b>Assegnazioni: </b>
                            <div v-for="assignation in sortedAssignationData">
                                {{ assignation.user.business_name }}: {{ assignation.quantity }}
                            </div>
                        </div>
                    </div>
                </div>
            </modal>

            <modal :show.sync="assignationsModal" @close="closeAssignationsModal" class="modal">
                <ValidationObserver ref="form">
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div v-if="selectedProduct" class="col-12">
                                <div>
                                    <b>ASSEGNAZIONI {{ selectedProduct.description }}</b>
                                </div>

                                <div v-if="assignationsData.length > 0">
                                    <div class="row" v-for="(assignation, index) in sortedAssignationData">
                                        <div class="col-6">
                                            <ValidationProvider v-slot="{ passed, failed, errors }" name="Agente" rules="required" mode="eager">
                                                <base-select required v-model="assignation.user.id" size="large" label="Agente" :items="agents"
                                                    autocomplete="business_name" item-name="business_name" placeholder="Agente"
                                                    :error="errors[0]"
                                                    :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                                                    filterable />
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-4">
                                            <ValidationProvider v-slot="{ passed, failed, errors }" name="Quantità" rules="required|min_value:1" mode="eager">
                                                <base-input v-model="assignation.quantity"
                                                    type="number"
                                                    label="Quantità"
                                                    placeholder="Quantità"
                                                    :error="errors[0]"
                                                    :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                                                    isNumericInteger
                                                />
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-2 justify-content-center align-items-center d-flex">
                                            <el-tooltip v-if="index > 0" content="Sposta Su" :open-delay="300" placement="top">
                                                <base-button class="remove btn-link" type="danger" size="sm" icon @click.native="moveAssignation(index, 'up')">
                                                    <i class="tim-icons icon-minimal-up" />
                                                </base-button>
                                            </el-tooltip>
                                            <el-tooltip v-if="index < (assignationsData.length - 1)" content="Sposta Giù" :open-delay="300" placement="top">
                                                <base-button class="remove btn-link" type="danger" size="sm" icon @click.native="moveAssignation(index, 'down')">
                                                    <i class="tim-icons icon-minimal-down" />
                                                </base-button>
                                            </el-tooltip>

                                            <el-tooltip content="Elimina" :open-delay="300" placement="top">
                                                <base-button class="remove btn-link" type="danger" size="sm" icon @click.native="removeAssignation(index)">
                                                    <i class="tim-icons icon-simple-remove" />
                                                </base-button>
                                            </el-tooltip>

                                            <div v-if="index >= (assignationsData.length - 1)">
                                                <el-tooltip content="Aggiungi" :open-delay="300" placement="top">
                                                    <base-button class="remove btn-link" type="danger" size="sm" icon @click.native="addAssignation()">
                                                        <mdicon name="plus" />
                                                    </base-button>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-6"></div>
                                      <div class="col-6">
                                        Totale: {{ totalAssigned }}
                                      </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-8 justify-content-center d-flex mt-5">
                                            <b>Nessuna Assegnazione</b>
                                        </div>
                                        <div class="col-2 justify-content-center align-items-center d-flex">
                                            <el-tooltip content="Aggiungi" :open-delay="300" placement="top">
                                                <base-button class="remove btn-link" type="danger" size="sm" icon @click.native="addAssignation()">
                                                    <mdicon name="plus" />
                                                </base-button>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>

                                <div style="margin-top: 16px;">
                                  <b>NOTA (visibile anche agli agenti)</b><br>
                                  <base-input v-model="selectedProduct.note"
                                              type="text"
                                              placeholder=""
                                  />
                                </div>

                                <div>
                                    <b>Multiplo minimo acquistabile</b>
                                    <base-input v-model="selectedProduct.minimum_purchasable_multiple"/>
                                </div>
                                <div>
                                  <base-checkbox v-model="selectedProduct.excluded_from_gift">
                                    <slot><b>Prodotto escluso dai calcoli degli omaggi</b></slot>
                                  </base-checkbox>
                                </div>

                                <div class="row">
                                    <div class="col-12 text-right mt-3">
                                        <base-button @click="saveAssignations" size="sm">SALVA</base-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </modal>
        </div>
    </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, min_value } from 'vee-validate/dist/rules'
import { ListTable, Modal } from '@/theme/components/index'
import { mapActions, mapGetters } from 'vuex'
import swal from "sweetalert2";

extend('required', required)
extend('min_value', min_value)

export default {
    components: {
        ListTable,
        Modal
    },

    props: {
        order: {
            type: Object,
            default: () => {
                return {}
            }
        },
        apiErrors: {
            type: [Object, String],
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            viewModal: false,
            assignationsModal: false,
            assignationsData: null,
            selectedProduct: null,
            totalAssigned: 0,
            query: {
                rowsPerPage: 10,
                page: 1,
                sort: null,
                filter: {
                    custom_search: ''
                }
            },
            pagination: {
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            searchFields: [
                {
                    type: 'input',
                    propName: 'custom_search',
                    name: 'Vino'
                }
            ],
            tableColumns: [
                {
                    prop: 'product_code',
                    label: 'Cod.',
                    sortable: true
                },
                {
                    prop: 'brand',
                    label: 'Azienda',
                    sortable: true
                },
                {
                    prop: 'description',
                    label: 'Vino',
                    sortable: true
                },
                {
                    prop: 'price',
                    label: 'List.',
                    sortable: true,
                    maxWidth: 40
                },
                {
                    prop: 'storage.qty_orderable_or_arrival_date',
                    label: 'Ord.',
                    sortable: false,
                    maxWidth: 40
                }
            ]
        }
    },

    watch: {
        query: {
            handler() {
                this.fetchProducts(this.query)
            },
            deep: true
        },
        meta: {
            handler(val) {
                this.pagination.total = val.total
            },
            deep: true
        },
        assignationsData: {
            handler(val) {
              this.totalAssigned = 0
              this.assignationsData.forEach((item) => {
                this.totalAssigned += parseInt(item.quantity)
              })
            },
            deep: true
        }
    },

    created() {
        this.fetchProducts(Object.assign({sync: true}, this.query))
        if (this.user.admin) {
          this.fetchAgents()
        }
    },

    beforeDestroy() {
        this.clearProducts()
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            products: 'products/items',
            agents: 'agents/items',
            meta: 'products/meta'
        }),
        sortedAssignationData() {
            return this.assignationsData ? this.assignationsData.sort((a, b) => a.rank < b.rank) : []
        },
        visibleProducts() {
          if (this.user.admin) {
            return this.products
          } else {
            return this.products.filter(product => product.visible)
          }
        }
    },

    methods: {
        ...mapActions({
            fetchProducts: 'products/fetch',
            deleteProduct: 'products/delete',
            updateProductAssignations: 'products/updateAssignations',
            updateProductVisibility: 'products/updateVisibility',
            fetchAgents: 'agents/fetch',
            clearProducts: 'products/resetItems'
        }),
        openShowModal(product) {
            this.selectedProduct = product
            this.assignationsData = [...product.assignations]
            this.viewModal = true
        },
        closeShowModal() {
            this.selectedProduct = null
            this.assignationsData = null
            this.viewModal = false
        },
        openAssignationsModal(product) {
            this.selectedProduct = product
            this.assignationsData = [...product.assignations]
            this.resortAssignations();
            this.assignationsModal = true
        },
        closeAssignationsModal() {
            this.selectedProduct = null
            this.assignationsData = null
            this.assignationsModal = false
        },
        addAssignation() {
            this.assignationsData.push({
                quantity: 1,
                rank: this.assignationsData.length + 1,
                user: {
                    id: null
                }
            })
        },
        removeAssignation(index) {
            this.assignationsData.splice(index, 1);
        },
        moveAssignation(index, direction) {
            let destination = direction === 'up' ? index - 1 : index + 1;
            let element = this.assignationsData.splice(index, 1)[0];
            this.assignationsData.splice(destination, 0, element);
            this.resortAssignations()
        },
        resortAssignations() {
            this.assignationsData.forEach((ass, index) => ass.rank = (index + 1));
        },
        saveAssignations() {
            this.$refs.form.validate().then((success) => {
                if (success) {
                    this.updateProductAssignations({
                        id: this.selectedProduct.id,
                        payload: {
                            assignations_data: this.assignationsData,
                            note: this.selectedProduct.note,
                            minimum_purchasable_multiple: this.selectedProduct.minimum_purchasable_multiple,
                            excluded_from_gift: this.selectedProduct.excluded_from_gift
                        }
                    }).then(res => {
                        this.selectedProduct = null
                        this.assignationsData = null
                        this.assignationsModal = false
                        this.viewModal = false
                        this.fetchProducts(Object.assign({ sync: true }, this.query))
                        this.$notify({
                            message: 'Assegnazioni aggiornate',
                            timeout: 5000,
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'success'
                        })
                    })
                } else {
                    this.$notify({
                        message: 'Sono presenti errori di validazione',
                        timeout: 5000,
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    })
                }
            })
        },
        handleDelete(product) {
          swal.fire({
            title: 'Sei sicuro?',
            text: `L'eliminazione del prodotto sarà irreversibile.`,
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill'
            },
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'No, annulla',
            buttonsStyling: false
          }).then((result) => {
            if (result.value) {
              this.executeDelete(product.id)
            }
          })
        },
        executeDelete(id) {
          this.deleteProduct(id).then((res) => {
            this.fetchProducts(this.query)
            this.$notify({
              message:
                'Prodotto eliminato con successo.',
              timeout: 5000,
              icon: '',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success'
            })
          })
        },
        toggleProductVisibility(data) {
          this.updateProductVisibility({ id: data.id, payload: { visible: !data.visible } }).then(res => {
            this.$notify({
              message: 'Visibilità aggiornata',
              timeout: 5000,
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success'
            })
            data.visible = !data.visible
          })
      }
    }
}
</script>

<style>
.ico-show {
  color: #27d927;
  font-weight: bold;
}
.ico-hide {
  color: #868686;
  font-weight: bold;
}
</style>
