import { mapActions, mapGetters } from 'vuex'

export default {
     data() {
        return {
            packagingCodes: ['NAKP3', 'NAKP6', 'MINIP6040', 'MINIP6080', 'EPAL1']
        }
    },
    computed: {
        orderObj() {
            return this.order || this.reservation
        },
        flatOrder() {
            return {
                customer_id: this.orderObj.customer ? this.orderObj.customer.id : null,
                customer_address_id: this.orderObj.customer_address ? this.orderObj.customer_address.id : null,
                payment_method_id: this.orderObj.payment_method ? this.orderObj.payment_method.id : null,
                shipping_method_id: this.orderObj.shipping_method ? this.orderObj.shipping_method.id : null,
                cart: this.flatCart,
                total: this.totalDiscounted,
                quotation: this.orderObj.quotation,
                reservation: this.orderObj.reservation,
                notes: this.orderObj.notes,
                order_status_id: this.orderObj.order_status_id,
                delivery_notes: this.orderObj.delivery_notes ? this.orderObj.delivery_notes : [],
                appointment_phone_number: this.orderObj.appointment_phone_number,
                delivery_floor: this.orderObj.delivery_floor,
                mandatory_delivery_date: this.orderObj.mandatory_delivery_date,
                mandatory_delivery_max_date: this.orderObj.mandatory_delivery_max_date,
                additional_costs: this.orderObj.additional_costs
            }
        },
        flatCart() {
            return this.orderObj.cart.map(item => { return { product_id: item.product.id, quantity: item.quantity, replacement: item.replacement,
                gift: item.gift, typology: item.typology, rank: item.rank, replacement_invoice_number: item.replacement_invoice_number,
                replacement_event_date: item.replacement_event_date, replacement_reason: item.replacement_reason,
                is_assigned: item.product.storage.is_assigned_to_current_user, automatic_gift: item.automatic_gift
            } })
        },
        flatTotal() {
            if (this.orderObj && this.orderObj.cart && this.orderObj.cart.length > 0) {
                let flatTotal = this.orderObj.cart.map(item => {
                    let price = (item.gift || item.replacement) ? 0 : (item.product.price || 0)
                    return parseFloat(price)
                }).reduce((prev, next) => prev + next)
                return flatTotal.toFixed(2);
            } else {
                return null
            }
        },
        totalQuantity() {
            if (this.orderObj && this.orderObj.cart && this.orderObj.cart.length > 0) {
                let filteredCart = this.orderObj.cart.filter(cartItem => this.packagingCodes.indexOf(cartItem.product.product_code) < 0)
                if(filteredCart.length > 0) {
                    let totalQuantity = filteredCart.map(item => item.quantity).reduce((prev, next) => prev + next)
                    return totalQuantity
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        totalQuantityWithoutGiftsAndReplacements() {
            if (this.orderObj && this.orderObj.cart && this.orderObj.cart.length > 0) {
                let filteredCart = this.orderObj.cart.filter(cartItem => (
                    this.packagingCodes.indexOf(cartItem.product.product_code) < 0
                    && !cartItem.gift
                    && !cartItem.replacement
                    && !cartItem.product.excluded_from_gift
                ))
                if(filteredCart.length > 0) {
                    let totalQuantity = filteredCart.map(item => item.quantity).reduce((prev, next) => prev + next)
                    return totalQuantity
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        totalAutomaticGiftsQuantity() {
            if (this.orderObj && this.orderObj.cart && this.orderObj.cart.length > 0) {
                let filteredCart = this.orderObj.cart.filter(cartItem => (
                    this.packagingCodes.indexOf(cartItem.product.product_code) < 0
                    && cartItem.automatic_gift
                ))
                if(filteredCart.length > 0) {
                    let totalQuantity = filteredCart.map(item => item.quantity).reduce((prev, next) => prev + next)
                    return totalQuantity
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        totalUnassignedFreeBottles() {
            if (this.orderObj && this.orderObj.cart && this.orderObj.cart.length > 0) {
                if (this.orderObj.payment_method.id == 6) {
                    let totalFreeBottles = Math.floor(this.totalQuantityWithoutGiftsAndReplacements / 60) * 6
                    return totalFreeBottles - this.totalAutomaticGiftsQuantity
                } else {
                    return 0
                }
            } else {
                return null
            }
        },
        total() {
            if (this.orderObj && this.orderObj.cart && this.orderObj.cart.length > 0) {
                let total = this.orderObj.cart.map(item => {
                    let price = (item.gift || item.replacement) ? 0 : (item.product.price || 0)
                    return price * item.quantity
                }).reduce((prev, next) => prev + next);
                return total.toFixed(2)
            } else {
                return null
            }
        },
        customerDiscountPercentage() {
            let discountPercentage = 0;
            if (this.orderObj && this.orderObj.customer) {
                discountPercentage += parseFloat(this.orderObj.customer.discount)
            }
            return discountPercentage.toFixed(2);
        },
        paymentMethodDiscountPercentage() {
            let discountPercentage = 0;
            if(this.orderObj && this.orderObj.payment_method && this.orderObj.payment_method.description == "ANTICIPATO") {
                discountPercentage += 4
            }
            return discountPercentage.toFixed(2);
        },
        discountPercentage() {
            let discountPercentage = parseFloat(this.customerDiscountPercentage) + parseFloat(this.paymentMethodDiscountPercentage)
            return discountPercentage.toFixed(2);
        },
        totalDiscount() {
            let totalDiscount = 0;
            let discountPercentage = parseFloat(this.discountPercentage)
            let total = parseFloat(this.total)
            totalDiscount = (total / 100).toFixed(3) * discountPercentage
            return totalDiscount.toFixed(2);
        },
        totalDiscounted() {
            let totalDiscounted = this.total - parseFloat(this.totalDiscount)
            return totalDiscounted.toFixed(2);
        },
        shippingCosts() {
            if(this.orderObj && this.orderObj.shipping_method) {
                if(this.orderObj.shipping_method.threshold && parseFloat(this.totalDiscounted) >= this.orderObj.shipping_method.threshold) {
                    return parseFloat(this.orderObj.shipping_method.threshold_price).toFixed(2)
                } else {
                    return parseFloat(this.orderObj.shipping_method.price).toFixed(2)
                }
            }
        },
        totalAdditionalCosts() {
            let total = 0
            if (this.orderObj && this.orderObj.additional_costs) {
                this.orderObj.additional_costs.forEach((item, index) => {
                    total += parseFloat(item.price)
                })
            }
            return total
        },
        totalDiscountedWithShipping() {
            if (this.orderObj && this.orderObj.shipping_method) {
                let totalDiscountedWithShipping = parseFloat(this.shippingCosts || 0) + parseFloat(this.totalDiscounted) + parseFloat(this.totalAdditionalCosts || 0)
                return totalDiscountedWithShipping.toFixed(2);
            } else {
                return this.totalDiscounted
            }
        },
        totalDiscountedWithShippingAndIva() {
            let total = parseFloat(this.totalDiscountedWithShipping) + ((this.totalDiscountedWithShipping / 100) * 22);
            return total.toFixed(2);
        }
    }
}
