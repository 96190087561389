<template>
    <div class="row">
        <div class="col-md-12 pt-2 mb-2 cart-summary-address">
            <b>Articoli inseriti nell'ordine:</b>
        </div>
        <div v-if="order && order.cart" class="col-md-12 pt-2 mb-2">
            <base-table :data="order.cart" thead-classes="text-primary">
                <template slot="columns">
                    <th class="cart-table-vino">Vino</th>
                    <th class="text-right">Listino</th>
                    <th class="text-right">Qta</th>
                    <th class="text-right">Totale</th>
                    <th v-if="!readonly" class="text-right">Ord.</th>
                    <th class="text-right">Tipologia</th>
                    <th v-if="!readonly"></th>
                </template>

                <template slot-scope="{ row, index }">
                  <td :id="row.product.product_code" class="cart-table-vino">
                    <p>{{ row.product.product_code }} - {{ row.product.description_with_note }}</p>
                    <span v-if="row.replacement">
                        <span v-if="!row.replacement_invoice_number || !row.replacement_event_date || !row.replacement_reason" style="color: red;">
                            <b>COMPLETARE LE NOTE DI SOSTITUZIONE</b>
                        </span>
                        <span v-else>
                            <b>Ft:</b> {{ row.replacement_invoice_number }} - <b>Data:</b> {{ row.replacement_event_date }}<br>
                            <b>Motivazione:</b> {{ row.replacement_reason }}
                        </span>
                    </span>
                  </td>
                  <td class="text-right">
                      <p>{{ parseFloat((row.product.price || 0)).toFixed(2) }} €</p>
                  </td>
                  <td style="max-width: 100px;" :class="(readonly || row.readonly) ? 'text-right' : 'input-text-right'">
                        <p v-if="!readonly && !row.readonly">
                            <!-- <base-select :value="row.quantity" size="large" flat
                                class="cart-select"
                                :items="isView ? Array.from({ length: row.product.storage.qty_orderable + row.quantity }, (_, i) => i + 1) : Array.from({ length: row.product.storage.qty_orderable }, (_, i) => i + 1)"
                                placeholder="Qta"
                                :class="[{ 'has-danger': (row.product.storage.qty_orderable < row.quantity)}]"
                                @input="updateProductQuantity({ index, value: $event })" /> -->
                            <base-input :value="row.quantity" size="small" flat
                                style="width: 90px; float: right;"
                                class="cart-select"
                                placeholder="Qta"
                                :class="[{ 'has-danger': (checkQtyError({ max_orderable_qty: row.product.storage.qty_orderable, qty: row.quantity, minimum_purchasable_multiple: row.product.minimum_purchasable_multiple }) !== '')}]"
                                :error="checkQtyError({ max_orderable_qty: row.product.storage.qty_orderable, qty: row.quantity, minimum_purchasable_multiple: row.product.minimum_purchasable_multiple })"
                                @input="updateProductQuantity({ index, value: $event })" />
                        </p>
                        <p v-else>
                            {{ row.quantity }}
                        </p>
                    </td>
                    <td>
                        <p class="text-right">{{ (row.gift || row.replacement) ? 0 : (row.product.price * row.quantity).toFixed(2) }} €</p>
                    </td>
                    <td v-if="!readonly">
                        <!-- <p v-if="isView && !isDraft" class="text-right">{{ parseFloat(row.product.storage.qty_orderable) + parseFloat(row.quantity) }}</p>
                        <p v-else class="text-right">{{ row.product.storage.qty_orderable }}</p>-->
                        <p class="text-right">{{ row.product.storage.qty_orderable }}</p>
                    </td>
                    <td style="max-width: 100px;" :class="(readonly || row.readonly) ? 'text-right' : null">
                        <p v-if="!readonly && !row.readonly">
                            <base-select :value="row.typology" size="large" flat class="cart-select"
                                :items="['Vendita', 'Sostituzione', 'Omaggio']" placeholder="Tipologia"
                                @input="updateType({ index, value: $event })" />
                        </p>
                        <p v-else>
                            <span :class="row.automatic_gift ? 'text-bold-red' : ''">{{ row.typology }}</span>
                        </p>
                    </td>
                    <td v-if="!readonly && !row.readonly" class="text-right">
                        <el-tooltip v-if="row.replacement" content="Dettaglio sostituzione" :open-delay="300" placement="top">
                            <base-button class="btn-link" type="warning" size="sm" icon
                                         @click.native="openEditReplacementModal({ index, value: $event })">
                                <i class="tim-icons icon-pencil" />
                            </base-button>
                        </el-tooltip>
                        <el-tooltip content="Rimuovi" :open-delay="300" placement="top">
                            <base-button class="btn-link" type="warning" size="sm" icon
                                @click.native="removeProduct({ index })">
                                <i class="tim-icons icon-trash-simple" />
                            </base-button>
                        </el-tooltip>
                    </td>
                    <td v-else></td>
                </template>

                <template slot="footer">
                    <tr>
                        <td><b>TOTALE IMPONIBILE</b></td>
                        <td class="text-right"><b>{{ flatTotal }} €</b></td>
                        <td class="text-right"><b>{{ totalQuantity }}</b></td>
                        <td class="text-right"><b>{{ total }} €</b></td>
                        <td v-if="!readonly"></td>
                        <td></td>
                        <td v-if="!readonly"></td>
                    </tr>
                    <tr>
                        <td><b>TOTALE SCONTO</b></td>
                        <td></td>
                        <td class="text-right">{{ discountPercentage }} %</td>
                        <td class="text-right"><b>{{ totalDiscount }} €</b></td>
                        <td v-if="!readonly"></td>
                        <td></td>
                        <td v-if="!readonly"></td>
                    </tr>
                    <tr v-if="shippingCosts">
                        <td><b>SPEDIZIONE</b></td>
                        <td></td>
                        <td></td>
                        <td class="text-right"><b>{{ shippingCosts }} €</b></td>
                        <td v-if="!readonly"></td>
                        <td></td>
                        <td v-if="!readonly"></td>
                    </tr>
                    <tr v-for="item in order.additional_costs">
                        <td><b>{{ item.description }}</b></td>
                        <td></td>
                        <td></td>
                        <td class="text-right"><b>{{ item.price }} €</b></td>
                        <td v-if="!readonly"></td>
                        <td></td>
                        <td v-if="!readonly"></td>
                    </tr>
                    <tr>
                        <td><b>TOTALE DOCUMENTO</b></td>
                        <td></td>
                        <td></td>
                        <td class="text-right"><b>{{ totalDiscountedWithShipping }} €</b></td>
                        <td v-if="!readonly"></td>
                        <td></td>
                        <td v-if="!readonly"></td>
                    </tr>
                    <tr>
                        <td><b>TOTALE IVATO</b></td>
                        <td></td>
                        <td class="text-right"><b>22 %</b></td>
                        <td class="text-right"><b>{{ totalDiscountedWithShippingAndIva }} €</b></td>
                        <td v-if="!readonly"></td>
                        <td></td>
                        <td v-if="!readonly"></td>
                    </tr>
                    <tr v-if="totalUnassignedFreeBottles > 0">
                        <td :colspan="readonly ? 5 : 7"><b>NOTA: {{ totalUnassignedFreeBottles }} PRODOTTI OMAGGIO NON ASSEGNATI PER MANCANZA GIACENZA</b></td>
                    </tr>
                </template>
            </base-table>

            <modal :show.sync="editReplacementModal" @close="closeEditReplacementModal" class="modal">
                <ValidationObserver ref="editReplacementForm">
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div v-if="selectedProduct" class="col-8">
                                <div>
                                    <b>PRODOTTO IN SOSTITUZIONE: {{ selectedProduct.product.description }}</b><br />
                                    QUANTITÀ: {{ selectedProduct.quantity }}
                                </div>

                                <div class="row">
                                    <div class="col-4 mt-3">
                                        Numero fattura
                                    </div>
                                    <div class="col-8 mt-3">
                                        <ValidationProvider v-slot="{ passed, failed, errors }" name="Numero Fattura" rules="required" mode="eager">
                                            <base-input
                                                v-model="selectedProduct.replacement_invoice_number"
                                                placeholder="Numero fattura"
                                                required
                                                :error="errors[0]"
                                                :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                                            />
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4 mt-3">
                                        Data di contestazione del danno
                                    </div>
                                    <div class="col-8 mt-3">
                                        <ValidationProvider v-slot="{ passed, failed, errors }" name="Data di contestazione del danno" rules="required" mode="eager">
                                            <base-input
                                                v-model="selectedProduct.replacement_event_date"
                                                placeholder="Data di contestazione del danno"
                                                required
                                                :error="errors[0]"
                                                :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                                            />
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4 mt-3">
                                        Motivazione della sostituzione
                                    </div>
                                    <div class="col-8 mt-3">
                                        <ValidationProvider v-slot="{ passed, failed, errors }" name="Motivazione" rules="required" mode="eager">
                                            <base-input
                                                v-model="selectedProduct.replacement_reason"
                                                placeholder="Motivazione"
                                                required
                                                :error="errors[0]"
                                                :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                                            />
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 text-right mt-3">
                                        <base-button
                                            @click="saveReplacementDetails"
                                            size="sm"
                                        >SALVA
                                        </base-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </modal>

        </div>
    </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, min_value } from 'vee-validate/dist/rules'
import { BaseTable, Modal } from '@/theme/components/index'

export default {
    components: {
        BaseTable,
        Modal
    },
    data () {
        return {
            selectedProduct: null,
            selectedProductIndex: null,
            editReplacementModal: false
        }
    },
    props: {
        order: {
            type: Object,
            default: () => {
                return {}
            }
        },
        isReviewTabActive: {
            type: Boolean,
            default: false
        },
        flatTotal: {
            type: String,
            default: null
        },
        totalQuantity: {
            type: Number,
            default: null
        },
        totalQuantityWithoutGiftsAndReplacements: {
            type: Number,
            default: null
        },
        totalUnassignedFreeBottles: {
            type: Number,
            default: null
        },
        total: {
            type: String,
            default: null
        },
        customerDiscountPercentage: {
            type: String,
            default: null
        },
        discountPercentage: {
            type: String,
            default: null
        },
        totalDiscount: {
            type: String,
            default: null
        },
        totalDiscounted: {
            type: String,
            default: null
        },
        totalDiscountedWithShipping: {
            type: String,
            default: null
        },
        totalDiscountedWithShippingAndIva: {
            type: String,
            default: null
        },
        shippingCosts: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        isDraft: {
            type: Boolean,
            default: false
        },
        isView: {
          type: Boolean,
          default: false
        }
    },


    computed: {
        isEdit() {
            return this.order.id != null
        }
    },

    methods: {
      updateProductQuantity(data) {
        let newQty = parseInt(data.value)
        if (!isNaN(newQty) && newQty > 0) {
          let cartItem = this.order.cart[data.index]
          cartItem.quantity = newQty
          this.$emit('onCartItemUpdate', { productData: cartItem, index: data.index })
        }
      },
      checkQtyError(data) {
        if (data.qty > data.max_orderable_qty) {
          return 'massimo ' + data.max_orderable_qty + ' !'
        } else if (data.qty < 1) {
          return 'minimo 1 !'
        } else if(data.minimum_purchasable_multiple > 0){
          if(data.qty % data.minimum_purchasable_multiple != 0){
            return 'deve essere multiplo di ' + data.minimum_purchasable_multiple + ' !'
          }
          return ''
        } else {
          return ''
        }
      },
        updateType(data) {
            let cartItem = this.order.cart[data.index]
            cartItem.typology = data.value
            cartItem.replacement = data.value === 'Sostituzione'
            cartItem.gift = data.value === 'Omaggio'
            cartItem.replacement_invoice_number = null
            cartItem.replacement_event_date = null
            cartItem.replacement_reason = null
            this.$emit('onCartItemUpdate', { productData: cartItem, index: data.index })

            if (cartItem.replacement) {
                this.openEditReplacementModal(data)
            }
        },
        removeProduct(data) {
            this.$emit('onCartItemRemove', data)
        },
        openEditReplacementModal (data) {
            this.selectedProduct = this.order.cart[data.index]
            this.selectedProductIndex = data.index
            this.editReplacementModal = true
        },
        closeEditReplacementModal () {
            this.selectedProduct = null
            this.selectedProductIndex = null
            this.editReplacementModal = false
        },
        saveReplacementDetails (data) {
            this.$refs.editReplacementForm.validate().then((success) => {
                this.$emit('onCartItemUpdate', {productData: this.selectedProduct, index: this.selectedProductIndex})
                this.$notify({
                    message: 'Informazioni aggiornate',
                    timeout: 5000,
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.closeEditReplacementModal()
            })
        }
    }
}
</script>

<style>
.input-text-right INPUT {
  text-align: right;
}
</style>
