var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar, 'no-scroll': _vm.lockScreen }},[_c('RotateScreen'),(_vm.lockScreen)?_c('LockScreen'):_vm._e(),_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[(_vm.$isAdmin())?_c('div',[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.dashboard'),
            iconFamily: 'tim',
            icon: 'tim-icons icon-chart-pie-36',
            path: '/riepilogo'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.news'),
            iconFamily: 'tim',
            icon: 'tim-icons icon-volume-98',
            path: '/news'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.admins'),
            iconFamily: 'mdi',
            icon: 'barcode',
            path: '/amministratori'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.agents'),
            iconFamily: 'mdi',
            icon: 'barcode',
            path: '/agenti'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.orders'),
            iconFamily: 'mdi',
            icon: 'note-text',
            path: '/ordini'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.reservations'),
            iconFamily: 'mdi',
            icon: 'note-text-outline',
            path: '/prenotazioni'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.customers'),
            iconFamily: 'mdi',
            icon: 'account-supervisor',
            path: '/clienti'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.storage'),
            iconFamily: 'mdi',
            icon: 'archive-outline',
            path: '/magazzino'
          }}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.schedule'),
              iconFamily: 'mdi',
              icon: 'calendar-clock',
              path: '/scadenziario'
            }}})],1):_c('div',[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.dashboard'),
            iconFamily: 'tim',
            icon: 'tim-icons icon-chart-pie-36',
            path: '/riepilogo'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.news'),
            iconFamily: 'tim',
            icon: 'tim-icons icon-volume-98',
            path: '/news',
            addBullet: this.unreadNews
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.orders'),
            iconFamily: 'mdi',
            icon: 'note-text',
            path: '/ordini'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.reservations'),
            iconFamily: 'mdi',
            icon: 'note-text-outline',
            path: '/prenotazioni'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.customers'),
            iconFamily: 'mdi',
            icon: 'account-supervisor',
            path: '/clienti'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.storage'),
            iconFamily: 'mdi',
            icon: 'archive-outline',
            path: '/magazzino'
          }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.personal_area'), iconFamily: 'mdi', icon: 'folder' }}},[_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.schedule'),
              iconFamily: 'mdi',
              icon: 'calendar-clock',
              path: '/scadenziario'
            }}}),(this.user.show_commissions)?_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.commissions'),
              iconFamily: 'mdi',
              icon: 'currency-eur',
              path: '/provvigioni'
            }}}):_vm._e()],1)],1)])],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }