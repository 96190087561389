const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Summary/Dashboard.vue')
const EditUserProfile = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/UserProfile/Edit.vue')
const SalesByProduct = () =>
    import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Summary/SalesByProduct.vue')
const SalesByCustomer = () =>
    import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Summary/SalesByCustomer.vue')

export default [
  {
    path: 'riepilogo',
    name: 'Dashboard',
    components: { default: Dashboard }
  },
  {
    path: '/me',
    name: 'Profilo',
    components: { default: EditUserProfile }
  },
  {
    path: 'vendite-per-prodotto/:year',
    name: 'Vendite per prodotto',
    components: { default: SalesByProduct }
  },
  {
    path: 'vendite-per-cliente/:year',
    name: 'Vendite per cliente',
    components: { default: SalesByCustomer }
  },
]
