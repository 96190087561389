<template>
    <div>
        <list-table v-model="query" :pagination="pagination" :columns="tableColumns" :items="visibleProducts" :meta="meta"
            :creable="false" :search-fields="searchFields" name-prop="name" hide-default-search
            :deletable="false" :editable="false" :showable="false" actions-label="" :actions-min-width="50" selectable @rowSelected="selectProduct">
            <template #extraActions="props">
                <el-tooltip v-if="(props.row.storage.qty_orderable > 0)" content="Seleziona" :open-delay="300" placement="top">
                    <base-button class="edit btn-link ico-plus-product" type="warning" size="md" icon @click.stop="selectProduct(props.row)">
                        <i class="tim-icons icon-simple-add" />
                    </base-button>
                </el-tooltip>
            </template>
        </list-table>
    </div>
</template>

<script>
import { ListTable } from '@/theme/components/index'
import { TableColumn } from 'element-ui'
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        ListTable,
        [TableColumn.name]: TableColumn
    },

    props: {
        order: {
            type: Object,
            default: () => {
                return {}
            }
        },
        apiErrors: {
            type: [Object, String],
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            query: {
                rowsPerPage: 10,
                page: 1,
                sort: null,
                filter: {
                    custom_search: ''
                }
            },
            pagination: {
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            searchFields: [
                {
                    type: 'input',
                    propName: 'custom_search',
                    name: 'Vino'
                }
            ],
            tableColumns: [
                {
                    prop: 'product_code',
                    label: 'Cod.',
                    sortable: true,
                    minWidth: 90
                },
                {
                    prop: 'brand',
                    label: 'Azienda',
                    sortable: true,
                    minWidth: 160
                },
                {
                    prop: 'description_with_note',
                    label: 'Vino',
                    sortable: true,
                    minWidth: 240
                },
                {
                    prop: 'price',
                    label: 'List.',
                    sortable: true
                },
                {
                    prop: 'storage.qty_orderable_or_arrival_date',
                    label: 'Ord.',
                    sortable: true
                }
            ]
        }
    },

    watch: {
        query: {
            handler() {
                this.fetchProducts(this.query)
            },
            deep: true
        },
        meta: {
            handler(val) {
                this.pagination.total = val.total
            },
            deep: true
        },
        apiErrors: {
            handler() {
                this.fetchProducts(this.query)
                this.order.cart.forEach((cartItem, index) => {
                    this.getProduct(cartItem.product.id).then(res => {
                        let product = res.data.content
                        this.selectProduct(product, index, cartItem.gift, cartItem.replacement, cartItem.typology)
                    })
                })
            },
            deep: true
        }
    },

    created() {
        this.fetchProducts(Object.assign({ sync: true }, this.query))
    },

    beforeDestroy() {
        this.clearProducts()
    },

    computed: {
        ...mapGetters({
            products: 'products/items',
            meta: 'products/meta'
        }),
        visibleProducts() {
            return this.products.filter(product => product.visible)
        }
    },

    methods: {
        ...mapActions({
            fetchProducts: 'products/fetch',
            getProduct: 'products/get',
            clearProducts: 'products/resetItems'
        }),
        validate() {
            return this.order.cart && this.order.cart.length > 0;
        },
        selectProduct(product, index = null, gift = false, replacement = false, typology = 'Vendita') {
            if (product.storage.qty_orderable > 0) {
                this.$emit('onCartItemUpdate', { index, productData: { product, quantity: product.minimum_purchasable_multiple || 1, typology, gift, replacement } })
                this.$nextTick(() => {
                  this.scrollToElement(product.product_code);
                });
            }
        },
        scrollToElement(itemId) {
          const element = document.getElementById(itemId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
    }
}
</script>
