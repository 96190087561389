<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header
          title="NEWS"
          :query="query"
          @backClick="$router.back()"
        />
        <list-table
          v-model="query"
          :pagination="pagination"
          :columns="tableColumns"
          :items="news"
          :meta="meta"
          :search-fields="searchFields"
          name-prop="title"
          hide-default-search
          :deletable="$isAdmin()"
          :creable="$isAdmin()"
          :editable="$isAdmin()"
          :showable="true"
          new-button-label="NUOVA NEWS"
          @onNew="$router.push('news/nuovo')"
          @onEdit="$router.push(`news/${$event.id}/modifica`)"
          @onShow="$router.push(`news/${$event.id}/visualizza`)"
          @onDelete="handleDelete($event.id)"
        >

          <template #customActions="props">
            <el-tooltip v-if="$isAdmin()" content="Invia via e-mail" :effect="props.darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
              <base-button class="edit btn-link" type="warning" size="sm" icon @click.native="handleSendNewsByEmail(props.row.id)">
                <i class="tim-icons icon-send" />
              </base-button>
            </el-tooltip>
          </template>

        </list-table>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'

import secureStorage from '@/utils/secureStorage'
import swal from "sweetalert2";

export default {
  components: {
    ListTable
  },

  data () {
    return {
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: ''
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
        {
          type: 'input',
          propName: 'custom_search',
          name: 'Cerca'
        }
      ],
      tableColumns: [
        {
          prop: 'title',
          label: 'Titolo',
          sortable: true,
          minWidth: 400,
          isHtml: true
        },
        {
          prop: 'date',
          label: 'Data',
          sortable: true,
          minWidth: 100
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      news: 'news/items',
      meta: 'news/meta',
      darkMode: 'settings/darkMode'
    })
  },

  watch: {
    query: {
      handler () {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler (val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created () {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'news') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy () {
    if (!this.news.some(news => !news.read_by_current_user)) {
      // faccio pulizia solo se non ci sono news da leggere, altrimenti mi incasina il pallino di notifica nel menu
      this.clearNews()
    }
  },

  methods: {
    ...mapActions({
      fetchNews: 'news/fetch',
      deleteNews: 'news/delete',
      clearNews: 'news/resetItems',
      sendNewsByEmail: 'news/sendNewsByEmail'
    }),
    handleFetch () {
      this.fetchNews(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'news', query: this.query }))
    },
    handleDelete (id) {
      this.deleteNews(id).then((res) => {
        this.fetchNews(this.query)
        this.$notify({
          message: 'News eliminata con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
      })
    },
    handleSendNewsByEmail (id) {
      swal.fire({
        title: 'Sei sicuro?',
        text: `Confermi l\'invio della news a tutti gli utenti ?`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Si, invia',
        cancelButtonText: 'No, annulla',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.executeSendNewsByEmail(id)
        }
      })
    },
    executeSendNewsByEmail (id) {
        this.sendNewsByEmail(id).then((res) => {
          this.$notify({
            message: 'News inviata con successo.',
            timeout: 5000,
            icon: '',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })
        })
    }

  }
}
</script>
