<template>
  <div class="row" v-if="news">
    <div class="col-12">
      <card>
        <page-header :title="pageTitle" :loading="isLoading" @backClick="$router.back()" />
      </card>
      <card>
        <div class="news-container">
          <div class="news-title">{{ news.title }}</div>
          <div class="news-image" v-if="newsImage">
            <img :src="newsImage.linkURL">
          </div>
          <div class="news-date">{{ news.date }}</div>
          <div class="news-body" v-html="news.body"></div>
          <div class="news-attachment-container" v-if="newsAttachments.length">
            <b>Allegati:</b>
            <div v-for="(file, index) in newsAttachments" :key="index" class="attachment-item">
              <div class="news-attachment-info">
                <a :href="file.linkURL" target="_blank">
                  <span>{{ file.label || 'Allegato ' + (index + 1) }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </card>
      <card v-if="news.readers">
        <b>News letta da:</b>
        <div class="news-readers-container"
             v-for="(user, index) in news.readers">
          <div class="news-reader">{{ user.business_name }}</div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import NewsForm from './Form.vue'

export default {
  components: { NewsForm },
  data () {
    return {
      newsId: null,
      isLoading: false,
      apiErrors: {},
      files: []
    }
  },

  computed: {
    ...mapGetters({
      news: 'news/single',
      newsList: 'news/items',
    }),
    pageTitle () {
      return 'VISUALIZZA NEWS'
    },
    newsImage() {
      return this.files.filter(file => file.attachmentType === 'news_image')[0]
    },
    newsAttachments() {
      return this.files.filter(file => file.attachmentType === 'news_attachment')
    }
  },

  created () {
    this.newsId = this.$route.params.id
    this.getNews(this.newsId).then(() => {
      this.news.attachments.forEach((attachment) => {
        this.files.push({
          attachmentType: attachment.type,
          label: attachment.label,
          linkURL: process.env.VUE_APP_API_BASE_URL + '/uploads/' + attachment.id
        })
      })
    })

    // rileggo NEWS per vedere se le ho lette tutte, così aggiorno il pallino di notifica nel menu
    this.fetchNews()
    if (!this.newsList.some(newsList => !newsList.read_by_current_user)) {
      // faccio pulizia solo se non ci sono news da leggere, altrimenti mi incasina il pallino di notifica nel menu
      this.clearNewsList()
    }
  },

  beforeDestroy () {
    this.clearNews()
  },

  methods: {
    ...mapActions({
      getNews: 'news/get',
      fetchNews: 'news/fetch',
      clearNews: 'news/resetSingle',
      clearNewsList: 'news/resetItems'
    }),
  }
}
</script>
<style>
.news-container {
  width: 100%;
  max-width: 1000px;
  text-align: left;
  margin: 0 auto;
}
.news-title {
  font-weight: bold;
  font-size: 2rem;
  text-align: left;
}
.news-date {
  text-align: left;
}
.news-image {
  width: 100%;
  text-align: center;
  padding: 30px;
}
.news-image IMG {
  max-width: 50%;
  max-height: 400px;
}
.news-body {

}
.news-attachment-container {
  margin-top: 20px;
}
.news-attachment-info {
  display: flex;
  flex-direction: column;
}
</style>
