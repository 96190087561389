import Crud from '../utils/crud'
import axios from "axios";
const crud = new Crud('/news')

const state = () => ({
  ...crud.state
})
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  async createNews (context, { payload }) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: context.state.endpoint,
        data: payload,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  async updateNews (context, { id, payload }) {
    return await new Promise((resolve, reject) => {

      payload.append('_method', 'PUT'); // can't use PUT directly with form-data
      axios({
        method: 'POST',
        url: id ? `${context.state.endpoint}/${id}` : context.state.endpoint,
        data: payload,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  async sendNewsByEmail (context, id) {
    return await new Promise((resolve, reject) => {

      axios({
        method: 'POST',
        url: `${context.state.endpoint}/${id}/sendNewsByEmail`,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
}
const mutations = {
  ...crud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
